






import { Component, Vue } from "vue-property-decorator";
@Component
export default class StepperSubmit extends Vue {
  //
  //
  // DATA
  private submitting = false;
  //
  //
  // METHODS
  submit() {
    this.submitting = true;
    setTimeout(async () => {
      const result = await this.$store.dispatch("ItemModule/submit");
      if (result) {
        await this.$store.dispatch("UserModule/info");
      }
      this.submitting = false;
      this.$emit("submitted", result);
    }, 0);
  }
}
